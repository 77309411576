import React, { useContext, useEffect } from "react"
import Header from "../../components/header/header.js"
import Description from "../../components/description/description.js"
import { ThemeContext } from "../../context/ThemeContext"
import Image from "../../components/image/image.js"
import Tile from "../../components/tile/tile.js"
import "./style.scss"
import BackTo from "../backTo/BackTo.js"
//import { Link } from "gatsby"
// import DuotoneImage from "../../components/duotoneImage/duotoneImage.js"

const TechSoftLayout = props => {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.setBackGroundColor("grey")
    theme.setVisibleFooter(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps



  return (
    <>
      <Header title={props.title} bannerImage={props.bannerImage} metaDescription={props.metaDescription}/>
      <Description type={props.descriptionType}>
        <h2>{props.description}</h2>
      </Description>

      <div className="tech-soft-contents">
        {props.descriptionType === "customAndWifi"
          ? (props.contents || []).map(i => (
              <div
                className="tech-soft-content columns p-2"
                key={Math.random()}
              >
                <div
                  className={`tech-soft-content__text column is-half-desktop is-half-tablet is-full-desktop`}
                >
                  <b>{i.date}</b>                  
                  {i.text.partOne || i.text}
                </div>
                {i.image && <SideImage image={i.image} alt={props.title} />}
                {i.text.partOne && i.text.partTwo && (
                  <div
                    className={`tech-soft-content__text column is-half-desktop is-half-tablet is-full-desktop`}
                  >
                    {i.text.partTwo}
                  </div>
                )}
              </div>
            ))
          : (props.contents || []).map((i, index) => (
              <div
                className="tech-soft-content columns p-2"
                key={Math.random()}
              >
                {index % 2 === 0 && (
                  <SideImage image={i.image} alt={props.title} left={true} />
                )}

                <div
                  className={`tech-soft-content__text column is-one-third-desktop is-half-tablet is-full-desktop`}
                >
                  {i.text}
                  {i.linkText && i.to && <a rel="noreferrer" href={i.to} target="_blank"><button className="goTo">{i.linkText}</button></a>}
                </div>

                {index % 2 !== 0 && (
                  <SideImage image={i.image} alt={props.title} />
                )}
              </div>
            ))}
      </div>

      {props.children}

      {props.firstImage && props.secondImage && (
        <div className="tiles">
          <div className="columns">
            <div className={`column bigRectangle`}>
              <Image filename={props.firstImage} />
            </div>
            <div className={`column bigRectangle`}>
              <Image filename={props.secondImage} />
            </div>
          </div>
        </div>
      )}

      {props.PROJECTS && (
        <>
          <div className="otherProjects">
            <h2>Altri progetti</h2>
          </div>
          <div className="tiles">
            <div className="columns">
              {props.PROJECTS.filter(i => i.title !== props.title).map(
                project => (
                  <Tile
                    shape={"square project"}
                    key={project.title}
                    to={project.to}
                    filename={project.filename}
                    alt={project.title}
                    subtitle={project.subtitle}
                  ></Tile>
                )
              )}
            </div>
          </div>
        </>
      )}
      <BackTo
        to={theme.originPage?.to}
        label={theme.originPage?.label}
        classColor={"grey"}
        stopColor={"#DEDACA"}
      />
    </>
  )
}

export default TechSoftLayout

const SideImage = ({image, alt, left = false}) => (
  <div className={`imgDescription column is-one-third-desktop is-half-tablet is-full-desktop
        ${left ? 'is-offset-one-third-desktop' : ''}
  `}>
    <Image 
      className={`column is-two-thirds-tablet ${left ? 'is-offset-one-third-tablet' : ''}`}
      filename={image}
      alt={alt}/>
  </div>

)